import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import tasqFeedbackModule from './tasqFeedbackModule';
import axiosClient from '@/lib/rest/axiosClient';
import uniqid from 'uniqid';
import Vue from 'vue'
import { sleep } from '@/utils/helpers';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'gauageModule',
  store,
})
class GaugeModule extends VuexModule {


  tanksGaugesData: any = []

  nodeTanks: any = []

  isLoadingGuage = false


  @Mutation
  setTanksGaugesData(data): void {
    this.tanksGaugesData = data
  }


  @Mutation
  setIsLoadingGuage(data): void {
    this.isLoadingGuage = data
  }

  @Mutation
  setNodeTanks(data): void {
    this.nodeTanks = data
  }


  @Action
  async getWellTanks({nodeID,operator='murfin'}) {
    try {

      const {data}: any = await axiosClient.get('/tank-store/',{
        params: {
          operator_id:operator,
          nodeid: nodeID
        },
      });

      this.setTanksGaugesData(data)
    } catch (error) {
      console.log(error);
    }finally{

    }
  }


  @Action
  async getNodeTanks({operator,nodeID}) {
    try {

      const {data}: any = await axiosClient.get('/meta/node-tanks',{
        params: {
          operator:operator,
          nodeid: nodeID
        },
      });

      this.setNodeTanks(data)

    } catch (error) {
      console.log(error);
    }finally{

    }
  }

  @Action
  async updateGaugeForm(payload) {
    try {
      await axiosClient.put(`tank-store/`,{... payload});
    } catch (e) {
      console.log(e);
    }
  }



  @Action
  async uploadFiles(payload: any) {
    try {
      const { data } = await axiosClient.post('/ai/print-ticket/data', payload);

      console.log(data);
      return data
      console.log('Files posted successfully');
    } catch (e) {
      console.log(e);
    }
  }



}

export default getModule(GaugeModule);
